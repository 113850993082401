<template>
  <main>
    <div class="modal fade" id="modalCompanys" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle"
      data-bs-backdrop="status">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTitle">
              "{{ viewDataArray.nameCompany }}"
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">
            <p>Sector Empresarial: {{ viewDataArray.businessSector }}</p>
            <p>Responsable: {{ viewDataArray.responsableCompany }} {{ viewDataArray.surnamesCompany }}</p>
            <p>Teléfono: {{ viewDataArray.phoneCompany }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="aceptCompany()">
              Aceptar Empresa
            </button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteCompany()">
              Rechazar Empresa
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-title mt-5">
      <h3 class="card-label text-dark">Historial de Empresas</h3>
    </div>
    <table class="table align-middle table-responsive table-hover caption-top">
      <thead class="placeholder-glow">
        <tr align="center">
          <th scope="col">Nombre Empresa</th>
          <th scope="col">Sector Empresarial</th>
          <th scope="col">Correo de Contacto</th>
          <th scope="col">Documentos</th>
          <th scope="col">Fecha de Registro</th>
          <th scope="col">Estatus de Aceptacion</th>
          <th scope="col">Admin Cliente</th>
          <th scope="col">Cliente</th>
          <th scope="col">Módulo Administración</th>
          <th scope="col">Opciones</th>
        </tr>
      </thead>
      <tbody v-for="(company, i) in listCompany" :key="i + 1">
        <tr align="center">
          <td scope="row" style="text-transform: uppercase;">{{ company.nameCompany }}</td>
          <td>{{ company.businessSector }}</td>
          <td>{{ company.emailCompany }}</td>
          <td>
            <button class="btn btn-icon btn-hover-warning btn-sm" title="Ver Constancia Fiscal">
              <a :href="company.fileConstancia?.link" target="_blank" class="btn btn-primary">
                <box-icon type='solid' color="white" name='file-pdf'></box-icon>
              </a>
            </button>
            <!-- <button class="btn btn-icon btn- btn-hover-warning btn-sm" title="Ver Brochure">
              <a :href="company.file?.link" target="_blank" class="btn btn-primary">
                <box-icon type='solid' color="white" name='file-pdf'></box-icon>
              </a>
            </button> -->
          </td>
          <td>
            <span v-if="company.created_at">
              {{ moment(company.created_at).tz(`${userTimeZone}`).format("DD/MM/YYYY") }}
            </span>
            <span v-else-if="company.createdAt">
              {{ moment(company.createdAt).tz(`${userTimeZone}`).format("DD/MM/YYYY") }}
            </span>
          </td>
          <td>
            <p class="badge" :class="{ 'bg-danger': !company.status, 'bg-success': company.status, }">
              {{ company.status ? "Aceptado" : "Pendiente" }}
            </p>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center;">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" @change="updateMode('client', company)"
                    v-model="company.mode_client" role="switch" id="flexSwitchCheckDefault">
                </div>
              </span>
            </div>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center;">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" @change="updateMode('contributor', company)" type="checkbox"
                    v-model="company.mode_contributor" role="switch" id="flexSwitchCheckDefault">
                </div>
              </span>
            </div>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center;">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" @change="updateMode('admin', company)" type="checkbox"
                    v-model="company.mode_admin" role="switch" id="flexSwitchCheckDefault">
                </div>
              </span>
            </div>
          </td>
          <td>
            <button type="button" class="btn btn-icon btn-primary btn-hover-primary btn-sm mx-3"
              @click="viewData(company)" title="Ver Empresa">
              <box-icon type='solid' color="white" name='show'></box-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" v-on:click="getPreviousPage()">
            <a class="page-link" href="#">Anterior</a>
          </li>
          <li
            v-for="(page, i) in totalPages()"
            :key="i + 1"
            v-on:click="getDataPages(page)"
            v-bind:class="isActive(page)"
            class="page-item"
          >
            <a class="page-link" href="#">{{ page }}</a>
          </li>
          <li class="page-item" v-on:click="getNextPage()">
            <a class="page-link" href="#">Siguiente</a>
          </li>
        </ul>
      </nav> -->
  </main>
</template>

<script>
import { AdminService } from "@/services";
import moment from 'moment-timezone';
import { compileAsync } from "sass";
export default {
  data: () => ({
    table: null,
    elementPage: 25,
    dataPage: [],
    listCompany: [],
    modalCompany: null,
    id: null,
    idUser: null,
    status: null,
    actual: 1,
    viewDataArray: {},
    moment: moment,
  }),
  mounted() {
    this.getDataPages(1);
    this.getCompany();
    setTimeout(() => {
      this.initialModal();
    }, 1000);
  },
  methods: {
    initialModal() {
      this.modalCompany = new window.bootstrap.Modal(
        document.getElementById("modalCompanys"),
        {
          keyboard: false,
        }
      );
    },
    totalPages() {
      return Math.ceil(this.listCompany.length / this.elementPage);
    },
    async getCompany() {
      var resp = await AdminService.getCompany({ search: this.search });
      var { data } = resp.data;
      console.log(data);
      this.listCompany = data.reverse();
    },
    viewData(company) {
      this.viewDataArray = company;
      this.modalCompany.show();
      this.id = company._id;
      this.idUser = company._id_user;

    },
    aceptCompany() {
      let data = { id: this.id, idUser: this.idUser, status: this.status };
      AdminService.aceptCompany(data).then((res) => {
        this.$swal({
          position: "top-center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
        this.getCompany();
      })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    async updateMode(mode, item) {
      let data = {}
      if (mode === "client") {
        data = {
          mode: mode,
          id: item._id,
          mode_client: item.mode_client
        };
      }
      else if (mode === "admin") {
        data = {
          mode: mode,
          id: item._id,
          mode_admin: item.mode_admin
        };
      }
      else {
        data = {
          mode: mode,
          id: item._id,
          mode_contributor: item.mode_contributor
        };
      }
      await AdminService.updateMode(data).then((res) => {
        this.$swal({
          position: "top-center",
          icon: "success",
          text: res.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
        this.getCompany();
      })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    deleteCompany() {
      AdminService.deleteCompany({ id: this.id })
        .then((res) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.getCompany();
        })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },

    getDataPages(numberPage) {
      this.actual = numberPage;
      this.dataPage = [];
      let start = numberPage * this.elementPage - this.elementPage;
      let end = numberPage * this.elementPage;
      this.dataPage = this.listCompany.slice(start, end);
    },
    getPreviousPage() {
      if (this.actual > 1) {
        this.actual--;
      }
      this.getDataPages(this.actual);
    },
    getNextPage() {
      if (this.actual < this.totalPages()) {
        this.actual++;
      }
      this.getDataPages(this.actual);
    },
    isActive(numberPage) {
      return numberPage === this.actual ? "active" : "";
    },
  },
};
</script>
